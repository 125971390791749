import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/attendance",
    name: "attendance",
    component: () => import("../views/AttendanceView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/DashboardView.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    component: () => import("../views/ReportsView.vue"),
  },
  {
    path: "/ibotickets",
    name: "ibotickets",
    component: () => import("../views/IBOTicketsView.vue"),
  },
  {
    path: "/scantickets",
    name: "scantickets",
    component: () => import("../views/ScanTicketsView.vue"),
  },
  {
    path: "/ticketspos",
    name: "ticketspos",
    component: () => import("../views/TicketsPOSView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/LogoutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
