<template>
    <v-app-bar
        color="#DAA520"
        app
        density="compact"
        :elevation="3"
        fixed
      >
      <v-avatar size="40" class="mx-3">
        <v-img
          src="logo.png"
          @click.prevent="showDrawer"
        >
        </v-img>
      </v-avatar>
        <v-app-bar-title class="app-bar-title">{{ getPageTitle }}</v-app-bar-title>
        <v-btn v-if="productsOnCart > 0" flat color="#DAA520" class="mr-3 text-none text-black" stacked size="xs" to="/salesorder">
          <v-badge :content="productsOnCart" color="grey-lighten-1">
            <v-icon>fa-solid fa-cart-shopping</v-icon>
          </v-badge>
        </v-btn>
    </v-app-bar>
</template>

<script>
export default {
  methods: {
    showDrawer() {
      this.$store.dispatch('toggleShowDrawer');
    }
  },
  computed: {
    getPageTitle() {
      return this.$store.getters.pageTitle
    },
    productsOnCart() {
      return this.$store.getters.productsOnCart
    }
  }
}
</script>

<style scoped>
  .image {
    border: 1px;
    mix-blend-mode: multiply;
  }

  .app-bar-title {
    color: black;
  }

</style>