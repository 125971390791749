import { createStore } from "vuex";

export default createStore({
  state: {
    showDrawer: false,
    pageId: -1,
    pageTitle: "",
    userIsAuthenticated: false,
    userToken: "",
    userTokenValidUntil: "",
    iboId: 0,
    iboInfo: {
      id: 0,
      number: "",
      name: "",
      achievementLevelId: 0,
      achievementLevel: "",
      icon: "fa-solid fa-user",
      imageUrl: "",
    },
    eventInfo: {
      id: 0,
      code: "",
      name: "",
      addressId: 0,
      typeId: 0,
    },
    terminalId: "",
  },
  getters: {
    showDrawer(state) {
      return state.showDrawer;
    },
    pageHasDrawer(state) {
      return state.pageId > 0;
    },
    pageId(state) {
      return state.pageId;
    },
    pageTitle(state) {
      return "Rhinos - " + state.pageTitle;
    },
    iboInfo(state) {
      return state.iboInfo;
    },
    userIsAuthenticated(state) {
      state.userIsAuthenticated = localStorage.getItem("userIsAuthenticated");
      return state.userIsAuthenticated;
    },
    iboId(state) {
      return state.iboId;
    },
    eventInfo(state) {
      return state.eventInfo;
    },
    loginInfo(state) {
      const result = {
        iboInfo: state.iboInfo,
        eventInfo: state.eventInfo,
        terminalId: state.terminalId,
      };
      return result;
    },
    terminalId(state) {
      return state.terminalId;
    },
  },
  mutations: {
    toggleShowDrawer(state) {
      if (state.pageId > 0) {
        state.showDrawer = !state.showDrawer;
      } else {
        state.showDrawer = false;
      }
    },
    updatePageId(state, payload) {
      state.pageId = payload;
    },
    updatePageTitle(state, payload) {
      state.pageTitle = payload;
    },
    updateShowDrawer(state, payload) {
      state.showDrawer = payload;
    },
    updateLoginInfo(state, payload) {
      state.iboInfo = {
        id: payload.iboId,
        number: payload.iboNumber,
        name: payload.iboName,
        achievementLevel: payload.achievementLevel,
        achievementLevelId: payload.achievementLevelId,
        icon:
          payload.achievementLevelId > 1
            ? "fa-solid fa-gem"
            : "fa-solid fa-user",
        imageUrl: payload.imageUrl,
      };
      state.eventInfo = {
        id: payload.eventId,
        code: payload.eventCode,
        name: payload.eventName,
        addressId: payload.eventAddressId,
        typeId: payload.eventTypeId,
      };
      localStorage.setItem("iboInfo", JSON.stringify(state.iboInfo));
      localStorage.setItem("iboId", JSON.stringify(payload.id));
      localStorage.setItem("eventInfo", JSON.stringify(state.eventInfo));
      state.iboId = payload.iboId;
    },
    updateUserIsAuthenticated(state, payload) {
      state.userIsAuthenticated = payload;
      localStorage.setItem("userIsAuthenticated", payload);
    },
    updateTerminalId(state) {
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const charsLength = chars.length;
      let terminalId = "";
      for (let index = 0; index < 16; index++) {
        terminalId += chars.charAt(Math.floor(Math.random() * charsLength));
      }
      state.terminalId = terminalId;
      localStorage.setItem("terminalId", terminalId);
      localStorage.setItem("hasTerminalId", true);
    },
    hasTerminalId(state) {
      if (localStorage.getItem("hasTerminalId")) {
        state.terminalId = localStorage.getItem("terminalId");
      } else {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const charsLength = chars.length;
        let terminalId = "";
        for (let index = 0; index < 16; index++) {
          terminalId += chars.charAt(Math.floor(Math.random() * charsLength));
        }
        state.terminalId = terminalId;
        localStorage.setItem("terminalId", terminalId);
        localStorage.setItem("hasTerminalId", true);
      }
    },
    resetStoreInfo(state) {
      state.showDrawer = false;
      state.pageTitle = "";
      state.userIsAuthenticated = false;
      state.userToken = "";
      state.userTokenValidUntil = "1979-01-12 00:00:01";
      state.iboInfo = {
        id: 0,
        number: "",
        name: "",
        achievementLevelId: 0,
        achievementLevel: "",
        icon: "fa-solid fa-user",
        imageUrl: "",
      };
      state.eventInfo = {
        id: 0,
        code: "",
        name: "",
        addressId: 0,
        typeId: 0,
      };
      localStorage.clear();
      state.iboId = 0;
    },
    loadStoreInfo(state) {
      if (localStorage.getItem("userIsAuthenticated")) {
        state.userIsAuthenticated = true;
        state.iboId = localStorage.getItem("iboId");
        state.iboInfo = JSON.parse(localStorage.getItem("iboInfo"));
        state.eventInfo = JSON.parse(localStorage.getItem("eventInfo"));
      } else {
        state.userIsAuthenticated = false;
        state.iboId = 0;
        state.iboInfo = {
          id: 0,
          number: "",
          name: "",
          achievementLevelId: 0,
          achievementLevel: "",
          icon: "fa-solid fa-user",
          imageUrl: "",
        };
        state.eventInfo = {
          id: 0,
          code: "",
          name: "",
          addressId: 0,
          typeId: 0,
        };
      }
    },
  },
  actions: {
    async updateShowDrawer({ commit }, payload) {
      commit("updateShowDrawer", payload);
    },
    async updatePageId({ commit }, payload) {
      commit("updatePageId", payload);
    },
    async updatePageTitle({ commit }, payload) {
      commit("updatePageTitle", payload);
    },
    async toggleShowDrawer({ commit }) {
      commit("toggleShowDrawer");
    },
    async updateLoginInfo({ commit }, payload) {
      commit("updateLoginInfo", payload);
    },
    async updateUserIsAuthenticated({ commit }, payload) {
      commit("updateUserIsAuthenticated", payload);
    },
    async resetStoreInfo({ commit }) {
      commit("resetStoreInfo");
    },
    async loadStoreInfo({ commit }) {
      commit("loadStoreInfo");
    },
    async hasTerminalId({ commit }) {
      commit("hasTerminalId");
    },
  },
  modules: {},
});
