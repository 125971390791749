<template>
  <v-app>
    <NavBar />
  </v-app>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBar from '@/components/NavBar.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    NavBar,
  },
  async mounted() {
    await this.$store.dispatch('updatePageTitle', 'Inicio');
    if(!this.$store.getters.userIsAuthenticated) {
      this.$router.push('/login');
    } else {
      this.$router.push('/scantickets');
    }
  }
});
</script>
